import './Hero.scss';
import ComponentScroll from "./componentScroll";
import {useEffect} from "react";
import { gsap } from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";


const routeChange = () => {
    window.location.href = 'https://app.zenpatient.com/#/get-started';
}

function Hero() {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
    })

    return (
        <section className="hero zp-padding">
            <div className="hero__content">
                <h2 className="stone-600">Digital Health Made Easy</h2>
                <h4>Set up your digital healthcare platform today</h4>
                <button className="btn btn--small btn--stone" onClick={routeChange}>Get Started</button>
            </div>
            <div className="hero__scroll">
                <ComponentScroll />
            </div>
        </section>
    )
}

export default Hero;
