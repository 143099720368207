import {useEffect} from "react";
import { gsap } from "gsap";
import "./componentScroll.scss";


function ComponentScroll() {
    useEffect(() => {

        //initially colorize each box and position in a row
        gsap.set(".box", {
            y: (i) => i * 4500
        });

        gsap.to(".box", {
            duration: 250,
            ease: "none",
            y: "-=4500", //move each box 500px to right
            modifiers: {
                y: gsap.utils.unitize(y => parseFloat(y) % 4500) //force x value to be between 0 and 500 using modulus
            },
            repeat: -1,
        });


        //toggle overflow
       // overflow.addEventListener("change", applyOverflow);



    });
    return (
        <div className="wrapper">
            <div className="boxes">
                <div className="box" />
            </div>
        </div>
    )
}

export default ComponentScroll;
