import './Footer.scss';
import ActionBar from "../action-bar/ActionBar";

function Footer() {
    return(
        <div className="footer__container">
            <ActionBar/>
            <div className="zp-padding">
                <div className="footer__logo"/>
            </div>
        </div>
    )
}
export default Footer;
