import './ActionBar.scss';

const routeChange = () => {
    window.location.href = 'https://app.zenpatient.com/#/get-started';
}

function ActionBar() {
    return (
        <div className="action-bar__container">
            <div className="action-bar__inner">
                <div className="action-bar__description">
                    <h4 className="warm-100">Your healthcare platform starts today, <br/> not a year from now.</h4>
                </div>
                <button className="btn btn--small btn--warm-dark" onClick={routeChange}>Get Started</button>
            </div>
        </div>
    )
}

export default ActionBar;
