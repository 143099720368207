import {useState} from 'react';
import classNames from 'classnames';
import checkout from '../../../assets/images/checkout-how.png';
import review from '../../../assets/images/review-intake.png';
import tracking from '../../../assets/images/tracking.png';
import network from '../../../assets/images/network.png';
import care from '../../../assets/images/intakes.png';

import './HowWork.scss';
import './Cards.scss';

const cardStack = document.getElementsByClassName('card');
const cardArray = []; // Does not change

function cardInit() {
    for (let i=0; i<cardStack.length; i++) {
        const cNum = i + 1;
        const cName = 'card' + cNum.toString();
        cardStack[i].classList.add(cName);
        cardArray.push(cNum);
    };

};
window.addEventListener('load', function () {
    cardInit();
})


function nextCard(index, id) {

    const manage = document.getElementsByClassName('manage')[0];
    const schedule = document.getElementsByClassName('schedule')[0];
    const evaluate = document.getElementsByClassName('evaluate')[0];
    const track = document.getElementsByClassName('track')[0];
    const network = document.getElementsByClassName('network')[0];



    if (id === 'manage') {
        manage.className = 'card card1 manage';
        schedule.className = 'card card2 schedule';
        evaluate.className = 'card card3 evaluate';
        track.className = 'card card4 track';
        network.className = 'card card5 network';

    }

    if (id === 'schedule') {
        manage.className = 'card card5 manage';
        schedule.className = 'card card1 schedule';
        evaluate.className = 'card card2 evaluate';
        track.className = 'card card3 track';
        network.className = 'card card4 network';

    }

    if (id === 'evaluate') {
        manage.className = 'card card4 manage';
        schedule.className = 'card card5 schedule';
        evaluate.className = 'card card1 evaluate';
        track.className = 'card card2 track';
        network.className = 'card card3 network';
    }

    if (id === 'track') {
        manage.className = 'card card3 manage';
        schedule.className = 'card card4 schedule';
        evaluate.className = 'card card5 evaluate';
        track.className = 'card card1 track';
        network.className = 'card card2 network';
    }

    if (id === 'network') {
        manage.className = 'card card2 manage';
        schedule.className = 'card card3 schedule';
        evaluate.className = 'card card4 evaluate';
        track.className = 'card card5 track'
        network.className = 'card card1 network';
    }
}



function HowWork() {
    const [items, setItem] = useState([
        {
            title: 'Craft',
            description: 'Design the patient experience from beginning to end. Create custom patient intakes, messaging campaigns, and on-boarding process.',
            active: true,
            id: 'manage'
        },
        {
            title: 'Visit',
            description: 'Schedule and visit with patients with full synchronous and asynchronous telehealth support',
            active: false,
            id: 'schedule'
        },
        {
            title: 'Care',
            description: 'Review your patient cases and prescribe the appropriate care for your patients.',
            active: false,
            id: 'evaluate'
        },
        {
            title: 'Track',
            description: 'Prescription tracking and subscription management',
            active: false,
            id: 'track'
        },
        {
            title: 'Network',
            description: 'Stand up a next generation pharmacy network and physician network with industry grade patient safety',
            active: false,
            id: 'network'
        }
    ])

    const handleToggle = (items, item) => {
        let newItems = items.slice();
        const itemIndex = newItems.indexOf(item);
        newItems.forEach(i => i.active = false);
        newItems[itemIndex].active = true;
        setItem(newItems);
    }

    return (
        <div className="how-work__container zp-padding">
            <div className="how-work__title">
                <h1 className="stone-white">How We Work <br></br> For You</h1>
            </div>
            <div className="how-work__content">
                <div className="how-work__preview">
                    <div className="card-stack">
                        <div className="card manage" style={{backgroundImage: "url(" + review + ")"}}/>
                        <div className="card schedule" style={{backgroundImage: "url(" + checkout + ")"}} />
                        <div className="card evaluate" style={{backgroundImage: "url(" + care + ")"}}/>
                        <div className="card track" style={{backgroundImage: "url(" + tracking + ")" }} />
                        <div className="card network" style={{backgroundImage: "url(" + network + ")"}} />
                    </div>
                </div>
                <div className="how-work__feature-container">
                    <ul className="how-work__feature-list">
                        {
                            items.map((item, index) => {
                                return (
                                    <li key={index} className={classNames('how-work__feature-list-item ', {active: item.active})} onClick={
                                        () => {
                                            handleToggle(items, item)
                                            nextCard(index + 1, item.id)
                                        }
                                    }>
                                        <div className="how-work__item-title">
                                            <div className="how-work__item-number"><p className="no-margin"><strong>0{index + 1}</strong></p></div>
                                            <h3 className="no-margin">{item.title}</h3>
                                        </div>
                                        <h4 className="no-margin">{item.description}</h4>
                                    </li>
                                )
                            })
                        }
                    </ul>

                </div>
            </div>
        </div>
    )
}

export default HowWork;
