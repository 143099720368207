import './Header.scss';
import {useEffect} from 'react';
import { gsap } from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";




const routeChange = () => {
    window.location.href = 'https://app.zenpatient.com/#/get-started';
}

function Header() {

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);


        ScrollTrigger.create({
            trigger: '.hero',
            start: 'top',
            bottom: 'bottom',
            toggleClass: {targets: '.main-header', className: 'background'}
        })

        ScrollTrigger.create({
            trigger: '.text-block__container',
            start: 'top',
            bottom: 'bottom',
            toggleClass: {targets: '.main-header', className: 'stone-white'}
        })

        ScrollTrigger.create({
            trigger: '.feature-block__content',
            start: 'top',
            bottom: 'bottom',
            toggleClass: {targets: '.main-header', className: 'stone-white'}
        });



        window.onscroll = function() {
            if (window.scrollY === 0) {
                gsap.set('.main-header', {className: 'main-header background'});
            }
        }
    })



    return (
        <header className="main-header background">
            <div className="main-header__logo" />
            <ul className="main-header__navigation">
                <li className="main-header__nav-item"><a className="nav-item" title="Zen Patient Login" href="https://app.zenpatient.com/">Login</a></li>
                <li className="main-header__nav-item">
                    <button className="btn btn--small btn--stone" onClick={routeChange}>Get Started</button>
                </li>
            </ul>
        </header>
    )
}

export default Header;
