import './TextBlock.scss';
import {useEffect} from "react";
import { gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

function TextBlock() {

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.set('.text-block__title', {
            opacity: 0
        })
        gsap.timeline({
            scrollTrigger: {
                trigger: ".text-block__container",
                start: "start",
                end: "bottom",
                toggleActions: "play reverse play reverse",
                pin: true
            }
        }).to('.text-block__title', { opacity: 1, y: -50, duration: 0.5 })
    })

    return (

            <div className="text-block__container zp-padding">
                <div className="text-block__title">
                    <h1>A Healthcare <br></br> Platform built <br></br> with you in mind</h1>
                </div>
            </div>





    )
}

export default TextBlock;
