import './FeatureBlock.scss';
import {useEffect} from "react";
import { gsap } from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import featureBuild from '../../../assets/images/feature-build.svg';
import featureStart from '../../../assets/images/feature-start.svg';
import featureLaunch from '../../../assets/images/feature-launch.svg';
import TextBlockAlt from "./TextBlockAlt";
import ActionBar from "../action-bar/ActionBar";
import Platform from "../platform/Platform";
import HowWork from "../how-work/HowWork";
import Footer from "../footer/Footer";

function FeatureBlock() {

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        // Demo corrected on May 1st 2021

        gsap.set('.content',{ autoAlpha: 0 })
        gsap.set('.feature-block__feature-highlight', {autoAlpha: 0});
        gsap.set('.feature-block__tab-container', {autoAlpha: 1})

        const headlines = gsap.utils.toArray(".feature-block__tab-item");

        const totalDuration = 2000;
        const singleDuration = totalDuration / headlines.length;

        const lineTimeline = gsap.timeline();

        ScrollTrigger.create({
            trigger: ".pin-up",
            start: "start",
            end: "+=" + totalDuration,
            //markers: true,
            pin: true,
            animation: lineTimeline,
        });

        lineTimeline
            .to('.feature-block__tab-container', {duration: 0.25, scaleY: 1, opacity: 1, autoAlpha: 1})


        headlines.forEach((elem, i) => {
            const smallTimeline = gsap.timeline();

            const content = document.querySelector('.content-wrap');
            const featureItems = document.querySelector('.feature-block__feature-description')
            const relevantContent = content.querySelector('span.content-' + i);
            const featureContent = featureItems.querySelector('span.feature-' + i);

            ScrollTrigger.create({
                trigger: ".feature-block__container",
                start: "top -=" + ( singleDuration * i ),
                end: "+=" + singleDuration,
                //markers: true,
                animation: smallTimeline,
                toggleActions: "play reverse play reverse",
                onLeave: (e) => {
                    if (i === 2) {
                        gsap.set('.feature-3', {duration: 0.25, scaleY: 1, y: 0, opacity: 1, autoAlpha: 1})
                        gsap.set('.content-3', {duration: 0.25, scaleY: 1, y: 0, opacity: 1, autoAlpha: 1})
                    }
                },
                onEnterBack() {
                    if (i === 2) {
                        gsap.set('.feature-3', {duration: 0.25, scaleY: 1, y: 0, opacity: 0, autoAlpha: 0})
                        gsap.set('.content-3', {duration: 0.25, scaleY: 1, y: 0, opacity: 0, autoAlpha: 0})
                    }
                }
            });

            smallTimeline
                //.to(elem,{ duration: 0.25, fontSize: "40px", color: "orange"}, 0)
                .to(elem,{ duration: 0.25, backgroundColor: elem.dataset.bgcolor, color: elem.dataset.color, borderColor: 'transparent'}, 0)
                .to(elem,{ duration: 0.25, height: 50}, 0)
                .to(relevantContent, { duration: 0.25, y: 0, autoAlpha: 0 }, 0)
                .to(relevantContent, { duration: 0.25, y: 0, autoAlpha: 1 }, 0)
                .to(featureContent, { duration: 0.25, y: 0, autoAlpha: 0 }, 0)
                .to(featureContent, { duration: 0.25, y: 0, autoAlpha: 1 }, 0);
        });
    })

    return (
        <div className="feature-block__container">
                <div className="pin-up zp-padding">
                    <div className="feature-block__tab-container">
                        <div data-bgcolor="#08323B" data-duration="0.25" data-color="#CCDCDD" className="feature-block__tab-item">Build</div>
                        <div data-bgcolor="#8C4936" data-duration="0.25" data-color="#FFD7BB" className="feature-block__tab-item">Start</div>
                        <div data-bgcolor="#3C6E47" data-duration="1" data-color="#D1F9D5" className="feature-block__tab-item">Launch</div>
                    </div>
                   <div className="feature-block__content">

                        <div className="feature-block__feature-description">
                            <span className="feature-block__feature-highlight feature-0">
                                <h3 className="feature-block__feature-title">We've done all the heavy lifting, focus on your customers</h3>
                                <p>Avoid developing intake forms, patient dashboards, patient messaging, e-prescribe, billing, e-commerce software from scratch—we built it so you don't have to.</p>
                            </span>
                            <span className="feature-block__feature-highlight feature-1">
                                <h3 className="feature-block__feature-title">Sell directly and fulfill prescription medications to patients across the US.</h3>
                                <p>E-commerce and telehealth software don't play nice. We fixed that.</p>
                                <p>Don't waste your time negotiating and integrating with pharmacy fulfillment partners. ZenPatient connects digital health innovators to a unified telemedicine network allowing you to rapidly get to market.</p>
                            </span>
                            <span className="feature-block__feature-highlight feature-2">
                                <h3 className="feature-block__feature-title">Launch a doctor's network nationwide</h3>
                                <p>Launch, grow, and sustain a competitive telehealth service with ZenPatient. Access a nationwide clinician network through our white-labeled platform or API to power your brand of synchronous and asynchronous virtual care.</p>
                            </span>
                            <span className="feature-block__feature-highlight feature-3">
                                <h3 className="feature-block__feature-title">Launch a doctor's network nationwide</h3>
                                <p>Launch, grow, and sustain a competitive telehealth service with ZenPatient. Access a nationwide clinician network through our white-labeled platform or API to power your brand of synchronous and asynchronous virtual care.</p>
                            </span>
                        </div>
                       <div className="content-wrap">
                           <span className="content content-0"><div className="feature-block__image" style={{backgroundImage: "url(" + featureBuild + ")"}}/> </span>
                           <span className="content content-1"><div className="feature-block__image" style={{backgroundImage: "url(" + featureStart + ")"}}/></span>
                           <span className="content content-2"><div className="feature-block__image" style={{backgroundImage: "url(" + featureLaunch + ")"}}/></span>
                           <span className="content content-3"><div className="feature-block__image" style={{backgroundImage: "url(" + featureLaunch + ")"}}/></span>
                       </div>
                    </div>

                </div>
                <ActionBar />
                <TextBlockAlt/>
                <Platform />
                <HowWork />
                <Footer />


        </div>
);
}

export default FeatureBlock;
