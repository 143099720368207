import {useEffect} from "react";
import { gsap } from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import './TextBlockAlt.scss';
import preview from '../../../assets/images/preview-2.png';

const routeChange = () => {
    window.location.href = 'https://gethealthspan.com';
}


function TextBlockAlt() {

    useEffect(() => {
        const sectionTimeline = gsap.timeline();
        const textBlockScreenTimeline = gsap.timeline();

        gsap.registerPlugin(ScrollTrigger);
        gsap.set('.text-block-alt__title', {
            opacity: 0
        })
        sectionTimeline.to('.text-block-alt__title', { opacity: 1, duration: 0.5 })

        ScrollTrigger.create({
            trigger: ".text-block-alt__container",
            start: "start",
            end: "bottom",
            toggleActions: "play none none none",
            animation: sectionTimeline
        });


        ScrollTrigger.create({
            trigger: '.text-block-alt__title',
            start: '200px',
            end: 'bottom',
            animation: textBlockScreenTimeline,
        })
    })

    return (
        <div className="text-block-alt__container zp-padding marketing-site">
            <div className="text-block-alt__title">
                <h1>Start Growing<br/>Your Dream<br/>Practice</h1>
            </div>
            <div className="text-block-alt__sub-title">
                <h3>With ZenPatient's e-commerce, telemedicine, patient messaging solutions, HealthSpan was able to get to market fast.</h3>
            </div>
            <div className="text-block-alt__screen" style={{backgroundImage: "url(" +  preview +  ")"}}/>
            <div className="text-block-alt__footer">
                <div className="text-block-alt__company">
                    <h2>Health<br/>Span+</h2>
                </div>
                <div className="text-block-alt__company-desc">
                    <h4 className="cool-500">HealthSpan empowers its patients to re-gain control over the levers of aging that are at the foundation of most age-related chronic diseases.</h4>
                    <button className="btn btn--small btn--cool" onClick={routeChange}>View Website</button>
                </div>
            </div>


        </div>
    )
}

export default TextBlockAlt;
