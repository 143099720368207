import {useEffect} from "react";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import './Platform.scss';
import tree from '../../../assets/images/tree.svg';
import {gsap} from "gsap";

function Platform() {

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
    })

    return (
        <div className="platform__container zp-padding">
            <div className="platform__content">
                <div className="platform__description">
                    <h1 className="no-margin">Our Platform</h1>
                    <h3>Our platform provides all the telehealth, ecommerce and pharmacy fulfillment features you need to drive adoption.</h3>
                </div>
                <div className="platform__details">
                    <ul className="platform__list">
                        <li className="platform__list-item">
                            <span className="platform__item-index">01</span> <span className="platform__item-description">Create a brand that your patients will never forget.</span>
                        </li>
                        <li className="platform__list-item">
                            <span className="platform__item-index">02</span> <span className="platform__item-description">Dominate your market and own the direct to consumer distribution channel</span>
                        </li>
                        <li className="platform__list-item">
                            <span className="platform__item-index">03</span> <span className="platform__item-description">Communicate directly to your patients with data-driven patient life cycle messaging</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="platform__visual">
                <div className="platform__visual-image" style={{backgroundImage: 'url("'+ tree +'")'}} />
            </div>
        </div>
    )
}

export default Platform;
