
import './App.scss';
import Hero from "./components/homepage/hero/Hero";
import TextBlock from "./components/homepage/information/TextBlock";
import FeatureBlock from "./components/homepage/information/FeatureBlock";
import {Helmet} from "react-helmet";
import Header from "./components/homepage/header/Header";
import {useEffect} from 'react';
import {ScrollTrigger} from "gsap/ScrollTrigger";

function App() {

  useEffect(() => {
      ScrollTrigger.create({
          trigger: '.how-work__container',
          start: 'top',
          bottom: 'bottom',
          toggleClass: {targets: '.main-header', className: 'stone-black'}
      });

      ScrollTrigger.create({
          trigger: '.marketing-site',
          start: 'top',
          bottom: 'bottom',
          toggleClass: {targets: '.main-header', className: 'cool-100'}
      });

      ScrollTrigger.create({
          trigger: '.platform__container',
          start: 'top',
          bottom: 'bottom',
          toggleClass: {targets: '.main-header', className: 'background-alt'}
      });

      ScrollTrigger.create({
          trigger: '.action-bar__container',
          start: '-100px',
          bottom: 'bottom',
          toggleClass: {targets: '.main-header', className: 'hide'}
      });

  })
  return (
    <div>
      <Helmet>
          <meta property="og:url" content="https://zenpatient.com" />
          <meta property="og:site_name" content="ZenPatient | Digital Health Made Easy" />
          <meta property="og:title" content="ZenPatient"/>
          <meta property="og:description" content="We've done all the heavy lifting, focus on your customers, Avoid Building and designing intake forms, patient dashboards, patient messaging and telehealth software from scratch"/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content="https://zenpatient.com"/>
          <meta property="twitter:title" content="ZenPatient | Digital Health Made Easy"/>
          <meta property="twitter:description" content="We've done all the heavy lifting, focus on your customers, Avoid Building and designing intake forms, patient dashboards, patient messaging and telehealth software from scratch"/>
          <meta property="twitter:card" content="summary_large_image"/>
      </Helmet>
      <Header/>
      <Hero />
      <TextBlock/>
      <FeatureBlock/>
    </div>
  );
}

export default App;
